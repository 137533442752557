import React, { useState, useEffect } from "react";
import "../../App.css";
import EventCard from "./EventCard";
import { motion } from "framer-motion";

import axios from "axios";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      delayChildren: 3,
    },
  },
};

const EventList = () => {
  const [events, setEvents] = useState([]);
  const loadEvents = async () => {
    try {
      const res = await fetch("/.netlify/functions/getEvents");
      const events = await res.json();
      setEvents(events);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const eventList = () => {
    return events.map((currentevent) => {
      return <EventList currentevent={currentevent} key={currentevent._id} />;
    });
  };

  let eventsList = events;

  return (
    <div className="event-container">
      <div className="eventText">
        <h1>Hire for Events</h1>
        <h3>
          Hire sound and lighting packages for events. From wedding DJs and
          function bands to Corporate AV and Orchestral we have the equipment
          and expertise to tailor to your needs.
        </h3>
        <h3>
          For dry hire and bespoke packages please request a quotation from
          james@jps-av.co.uk
        </h3>
      </div>
      <motion.div
        className="card-container"
        // variants={containerVariants}
      >
        {eventsList.map((item, i) => (
          <EventCard item={item} key={item._id} i={i} />
        ))}
      </motion.div>
    </div>
  );
};

export default EventList;
