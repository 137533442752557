import React from "react";
import "../App/App.css";
import Footer from "../App/Components/Footer";
import { motion } from "framer-motion";
import EventList from "../App/Components/Cards/EventList";
import VideosList from "../App/Components/Cards/VideoList";
import PlaceHolderVideos from "../App/Components/Cards/PlaceHolderVideos";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
    },
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const ServicesPage = ({ openModal }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="page-content">
        <div className="event-hire">
          {/* <EventList openModal={openModal}/> */}
          <EventList />
        </div>
        <div className="video-hire">
          {/* <VideoList /> */}
          <VideosList />
        </div>
      </div>
      <Footer openModal={openModal} />
    </motion.div>
  );
};

export default ServicesPage;
