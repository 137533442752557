import React from "react";
import "./App.css";

const ContactButton = ({ openModal }) => {
  return (
    <div>
      <button className="button" onClick={openModal}>
        <h4>Get In Touch</h4>
      </button>
    </div>
  );
};

export default ContactButton;
