import React  from 'react';
import TextLogoSVG from '../App/Components/TextLogoSVG'
import VideoPlayer from '../App/Components/VideoPlayer'
import {motion} from 'framer-motion'
import ServicesArray from '../App/Components/ServicesArray';
import Footer from '../App/Components/Footer'
import TextLogoPNG from '../images/Logos/JPS_Logo.png'

const containerVariants ={
    hidden:{
        opacity: 0,     
    },
    visible:{
        opacity: 1,  
        transition: {duration: 0.5},
        x: 0
    },
    exit:{
        opacity: 0,    
        transition:{ease: 'easeInOut'}
    }
}

const HomePage = ({openModal}) => {
    
        return (
                <motion.div 
                className="home-page"
                variants={containerVariants}
                initial='hidden'
                animate='visible'
                exit='exit'

                >
                   
                    <div className="home-page-container">
                        <TextLogoSVG className="TextLogoSVG"/> 
                    </div>
                    <div className="home-page-container-alt">
                        <img src={TextLogoPNG} alt="" ></img>
                    </div>
                    <div className="services-array-container">
                        <ServicesArray />
                    </div>
                    <motion.div>
                        <VideoPlayer />
                    </motion.div> 
                    <Footer openModal={openModal}/>
                </motion.div>
        ) 
    
}

export default HomePage;