import React, { useState, useEffect } from "react";
import "../App.css";
import NavBar from "./NavBar";

// import HomePage from '../../Pages/Home';
import { BrowserRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faEnvelopeSquare,
  faTimes,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import PageRouter from "./PageRouter";
import NewContactModal from "../Components/Contact/ContactModal/NewContactModal";
import NavButton from "./NavButton";
import SideBar from "./SideBar";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faFacebookF,
  faInstagram,
  faEnvelopeSquare,
  faTimes,
  faBars
);
const App = () => {
  let [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [showNav, setShowNav] = useState(false);
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  let [events, setEvents] = useState("");

  const [showMenu, setShowMenu] = useState(true);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    console.log(showMenu);
  };

  return (
    <div>
      <NavButton
        toggleMenu={toggleMenu}
        showMenu={showMenu}
        toggleNav={toggleNav}
      />

      <Router>
        <SideBar
          openModal={openModal}
          showNav={showNav}
          setShowNav={setShowNav}
          toggleNav={toggleNav}
          toggleMenu={toggleMenu}
        />
        <NavBar
          openModal={openModal}
          showNav={showNav}
          toggleNav={toggleNav}
          toggleMenu={toggleMenu}
        />
        <PageRouter openModal={openModal} />
      </Router>
      <NewContactModal
        showModal={showModal}
        setShowModal={setShowModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default App;
