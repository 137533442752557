import React from "react";
import "../App.css";
import ClearStage from "../../images/Website Images/JPS Clear Stage.jpg";

import ContactButton from "../Button";

const WhatWeDo = ({ openModal, showModal, setShowModal }) => {
  return (
    <div className="what-we-do-container">
      <div className="image-container">
        <img src={ClearStage} alt="" />
      </div>
      <div className="info-container">
        <h3>WHAT WE DO</h3>
        <h4>
          JPS is an independent audio visual company operating within bespoke
          events and equipment hire. From corporate AV to weddings, music
          concerts, touring logistics and festivals we have the industry
          knowledge and support to make sure your events operate to the highest
          standard.{" "}
        </h4>
        <h4>
          Founded in early 2018, JPS-AV have worked with a multitude of clients
          such as the NHS, Nickelodeon, Nitelites, Under The Covers Band, Girls
          that Mix, Snarky Puppy, Bill Lawrence, Knower, Gary Numan, Rick
          Wakeman and Gretchen Peters.
        </h4>
        <h4>
          JPS are committed to make every event special and unique, delivering
          high quality AV at affordable rates.
        </h4>
        <ContactButton
          showModal={showModal}
          setShowModal={setShowModal}
          openModal={openModal}
        />
      </div>
    </div>
  );
};

export default WhatWeDo;
