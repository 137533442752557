import * as React from "react";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";

const variants = {
  enter: {
    opacity: 0,
    y: -50,
  },
  center: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 50,
  },
};

const words = [
  "Event Production",
  "Audio and Video Recording",
  "Mixing and Mastering",
  "Audio and Lighting Hire",
  "Lighting Design",
];

const ServicesArray = () => {
  let [word, setWord] = useState(0);
  const wordIndex = wrap(0, words.length, word);

  useEffect(() => {
    const interval = setInterval(() => {
      setWord(word + 1);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.h1
          key={word}
          id="services-array"
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 200 },
            opacity: { duration: 0.5 },
          }}
        >
          {words[wordIndex]}
        </motion.h1>
      </AnimatePresence>
    </>
  );
};

export default ServicesArray;
