import React from "react";
import "../App.css";

import JamesSoundBoard from "../../images/Website Images/James Sound Board10.jpg";

class Testimonials extends React.Component {
  render() {
    return (
      <div className="testimonials-container">
        <div className="info-container">
          <h3>TESTIMONIALS</h3>
          <h4 className="quote">
            "JPS provided fantastic sound for the live night of our Sing City
            project. The event showcases young and emerging bands and musicians
            so we have a range of acoustic and electronic instrument and a
            number of different line-ups throughout the evening! James did a
            brilliant job at making everyone feel really comfortable and
            confident in the space during soundcheck and the sound during the
            performance was brilliant. Thank you James for a great night"
          </h4>
          <h3>(Kate Lowes, Head of Programmes, Brighter Sound)</h3>
        </div>
        <div className="image-container">
          <img src={JamesSoundBoard} alt="" />
        </div>
      </div>
    );
  }
}

export default Testimonials;
