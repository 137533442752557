import React, { useState, useEffect } from "react";
import SmallVenue from "../../../images/Website Images/Small Venue.jpg";
import MediumVenue from "../../../images/Website Images/Medium Venue 2.jpg";
import LargeVenue from "../../../images/Website Images/Session Orchestra.jpg";

import { motion, useAnimation } from "framer-motion";

const imagesArray = [SmallVenue, MediumVenue, LargeVenue];

const containerVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 3,
      duration: 0.5,
    },
  }),
};
const childVarients = {
  hidden: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0.5,
    },
  },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: i * 0.3,
    },
  }),
};

const EventCard = (props) => {
  return (
    <motion.div
      className="card"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      animate="visible"
      initial="hidden"
      custom={props.i}
      variants={childVarients}
    >
      <div className="image-crop">
        {/* <img src={props.item.imageURL} alt="" /> */}
        <img src={imagesArray[props.i]} />
      </div>
      <div className="card-text">
        <div>
          <h1>{props.item.title}</h1>
          <h6>{props.item.tag}</h6>
          <h3>{props.item.description}</h3>
        </div>
        <div className="event-more-info">
          <div>
            <h5>From:</h5>
            <h1>{props.item.price}</h1>
          </div>
          {/* <button className="Button" onClick={openModal}><h4>More Info</h4></button> */}
        </div>
      </div>
    </motion.div>
  );
};

export default EventCard;
