import React from "react";
import "./Contact.css";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const outerContainer = {
  hidden: { y: 100 },
  visible: { y: 0 },
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      subject: "",
      phoneNumber: "",
    };
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contactJPS", ...this.state }),
    })
      .then(() => alert("Success! Thanks for getting in touch!"))
      .catch((error) => alert(error));

    e.preventDefault();
    this.props.closeModal();
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message, subject, phoneNumber } = this.state;
    return (
      <motion.div
        className="outer-container"
        vatiants={outerContainer}
        animate="visible"
        initial="hidden"
        onClick={this.handleClick}
      >
        <div className="contact-container">
          <div className="contact-close">
            <h1>Contact</h1>
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="web-icon"
              onClick={this.props.closeModal}
            />
          </div>
          <h4>To arrange a quote or for more information:</h4>
          <h4>James@jps-av.co.uk</h4>
          <h4>James: 07873241250</h4>
          <form onSubmit={this.handleSubmit} className="contact-form">
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
              placeholder="name"
              required="required"
            />
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              placeholder="email"
              required="required"
            />
            <input
              type="text"
              name="subject"
              value={subject}
              onChange={this.handleChange}
              placeholder="subject"
              required="required"
            />
            <input
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onChange={this.handleChange}
              placeholder="phoneNumber"
              required="required"
            />
            <textarea
              name="message"
              value={message}
              onChange={this.handleChange}
              placeholder="message"
              className="message"
              required="required"
            />
            <button type="submit">Send</button>
          </form>
        </div>
      </motion.div>
    );
  }
}

export default Contact;
