import React from 'react'

import {motion, AnimatePresence} from 'framer-motion'
import '../../../App.css'
import Contact from '../Contact'

const backdrop = {
    visible: {opacity: 1},
    hidden: {opacity:0}
}

const NewContactModal = ({showModal, setShowModal, closeModal}) => {

    
    return(        
        <AnimatePresence exitBeforeEnter>
              {showModal &&( <motion.div 
              onClick={closeModal}
              className="modal-backdrop" 
              variants={backdrop}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key="key">                    
                    <Contact closeModal= {closeModal} />                   
                </motion.div>
                 
            )}
        </AnimatePresence>
    )
}

export default NewContactModal;