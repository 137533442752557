import React from "react";
import Andchuck from "../../../images/Website Images/Film Shoots/Andchuck Film Shoot.jpg";
import Lisa from "../../../images/Website Images/Film Shoots/Lisa Olivant Film Shoot.jpg";
import YoungM from "../../../images/Website Images/Film Shoots/Young Monarch Film Shoot.jpg";
import Untold from "../../../images/Website Images/Film Shoots/Untold.jpg";
import MacFray from "../../../images/Website Images/Film Shoots/MacFray.jpg";
import Flightless from "../../../images/Website Images/Film Shoots/Flightless.jpg";
import { motion, useAnimation } from "framer-motion";

const imageArray = [Andchuck, YoungM, Lisa, Untold, MacFray, Flightless];

const containerVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      delayChildren: 3,
      duration: 0.5,
    },
  }),
};
const childVarients = {
  hidden: { opacity: 0, y: 100 },
  visible: (i) => ({
    opacity: 1,
    y: 0,

    transition: {
      delay: i * 0.3,
    },
  }),
};
const youtubeLinkClick = (url) => {
  window.open(url, "_blank");
};

const VideoCard = (props) => {
  const controls = useAnimation();
  controls.start({
    opacity: 1,
    transition: { delay: 1, duration: 1 },
  });
  return (
    <motion.div
      className="video-card"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      animate="visible"
      custom={props.i}
      variants={childVarients}
    >
      <div className="image-crop">
        {/* <img src={props.item.imageURL} alt="" /> */}
        <img src={imageArray[props.i]} alt="" />
      </div>
      <div className="card-text">
        <div>
          <h1>{props.item.artist}</h1>
          <h4>{props.item.title}</h4>
          <h6>{props.item.location}</h6>
          <h3>{props.item.description}</h3>
        </div>
        <button
          onClick={() => {
            youtubeLinkClick(props.item.videoURL);
          }}
        >
          <h4>Watch Video</h4>
        </button>
      </div>
    </motion.div>
  );
};

export default VideoCard;
