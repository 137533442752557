import React from "react";
import { motion } from "framer-motion";

import "../App.css";

// const faderY = [122.56, 144.41, 240.18, 151.86, 151.89, 137.54]

const trackVariants = {
  hidden: {
    pathLength: 0,
    y: 150,
    scale: 0,
  },
  visible: {
    pathLength: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 5,
      type: "spring",
      damping: 10,
      stiffness: 40,
    },
  },
};

const circleVariants = {
  hidden: {
    opacity: 0,
    x: -280,
    y: -303,
  },
  visible: {
    // x: -276.84,
    // y: 304.87,

    x: -280,
    y: -303,
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
};

const faderVariants = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,

    transition: {
      delay: 2,
      duration: 2.5,
      ease: "easeInOut",
    },
  },
};
const logoTextVariants = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      delay: 5,
      duration: 2.5,
      ease: "easeInOut",
    },
  },
};

const logoGroupVariants = {
  hidden: {
    scale: 1.2,
    x: "-44vw",
  },
  visible: {
    scale: 1,
    x: 0,
    transition: {
      delay: 3.5,
      duration: 1.5,
    },
  },
};

const trackGroupVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 2.5,
      staggerChildren: 0.2,
    },
  },
};

const TextLogoSVG = () => {
  return (
    <svg
      className="JPSSVG"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1369.23 471.4"
      // variants={svgVariants}
      // initial="hidden"
      // animate="visible"
    >
      {/* <defs>
                <linearGradient id="linear-gradient" x1="3023.19" y1="4508.93" x2="2809.45" y2="4329.58" gradientTransform="translate(-1494.71 -3882.71)" gradientUnits="userSpaceOnUse">
                <stop offset="0.51"/>
                <stop offset="0.98" stop-opacity="0.55"/>
                <stop offset="1" stop-opacity="0.5"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="1580.02" y1="695.03" x2="1264.9" y2="379.91" gradientUnits="userSpaceOnUse">
                <stop offset="0.43"/>
                <stop offset="0.91" stop-opacity="0.67"/>
                <stop offset="0.98" stop-opacity="0.5"/>
                </linearGradient>
            </defs> */}
      <title>JPS_Logo</title>

      <motion.g variants={logoGroupVariants}>
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 2 } }}
          d="M1420.46,676.58a141,141,0,1,0-87.16-251.84,66.85,66.85,0,0,1-40.61,51.17,141,141,0,0,0,127.77,200.67Z"
          transform="translate(-276.84 -304.47)"
          style={{ fill: "black" }}
          // style={{fill:url(linear-gradient)}}
        />
        <motion.g className="tracks" variants={trackGroupVariants}>
          <motion.rect
            variants={trackVariants}
            x="1121.63"
            y="128.17"
            width="14.72"
            height="176.64"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.88 }}
          />
          <motion.rect
            variants={trackVariants}
            x="1062.64"
            y="216.49"
            width="14.72"
            height="88.32"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.88 }}
          />
          <motion.rect
            variants={trackVariants}
            x="1092.16"
            y="245.79"
            width="14.72"
            height="74"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.88 }}
          />
          <motion.rect
            variants={trackVariants}
            x="1151.08"
            y="157.47"
            width="14.72"
            height="176.64"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.65 }}
          />
          <motion.rect
            variants={trackVariants}
            x="1209.99"
            y="157.5"
            width="14.72"
            height="88.32"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.65 }}
          />
          <motion.rect
            variants={trackVariants}
            x="1180.54"
            y="143.15"
            width="14.72"
            height="102.64"
            rx="7.36"
            ry="7.36"
            style={{ fill: "#fff", opacity: 0.65 }}
          />
        </motion.g>
        <g className="faders">
          <motion.rect
            variants={faderVariants}
            x="1334.58"
            y="533.34"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="matrix(-1, 0, 0, -1, 2416.8, 804.01)"
            style={{ fill: "#fff" }}
          />
          <motion.rect
            variants={faderVariants}
            x="1364.1"
            y="567.55"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="translate(2475.84 872.44) rotate(-180)"
            style={{ fill: "#fff" }}
          />
          <motion.rect
            variants={faderVariants}
            x="1393.57"
            y="532.79"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="matrix(-1, 0, 0, -1, 2534.78, 802.91)"
            style={{ fill: "#fff" }}
          />
          <motion.rect
            variants={faderVariants}
            x="1452.48"
            y="462.11"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="translate(2652.6 661.56) rotate(-180)"
            style={{ fill: "#fff" }}
          />
          <motion.rect
            variants={faderVariants}
            x="1481.93"
            y="497.39"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="translate(2711.51 732.11) rotate(-180)"
            style={{ fill: "#fff" }}
          />
          <motion.rect
            variants={faderVariants}
            x="1423.03"
            y="497.36"
            width="24.52"
            height="41.7"
            rx="8.31"
            ry="8.31"
            transform="translate(2593.69 732.06) rotate(-180)"
            style={{ fill: "#fff" }}
          />
        </g>
        <motion.path
          variants={circleVariants}
          initial="hidden"
          animate="visible"
          d="M1207.66,461.14a225.61,225.61,0,1,0,91.92-115.91,74.07,74.07,0,0,1,15,17,202.68,202.68,0,0,1,106-29.82c112,0,203.13,91.13,203.13,203.14s-91.12,203.14-203.13,203.14-203.14-91.13-203.14-203.14a202.87,202.87,0,0,1,10.2-63.71A73.4,73.4,0,0,1,1207.66,461.14Z"
          // transform="translate(-276.84 -304.47)"
          //style={{fill:url(#linear-gradient-2)}}
        />
      </motion.g>
      <motion.g className="logo-text" variants={logoTextVariants}>
        <path
          d="M491.1,546q0,57.18-29.38,89.5T378.9,667.81q-48.63,0-75.34-24.85t-26.72-67.59A134.3,134.3,0,0,1,279,550.26l25.11-5.88a200.12,200.12,0,0,0-1.6,24.58q0,74.26,75.87,74.27,44.88,0,65.46-26.18t20.57-76.94V309.82H491.1Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M738.44,337.33q32.31,27.53,32.32,75.61t-32.32,75.87q-32.34,27.8-87.36,27.79h-90.3V662.46H534.06V309.82h117Q706.1,309.82,738.44,337.33ZM718.13,471.18q24.32-20.3,24.31-58.24,0-37.4-24.31-58T647.87,334.4H560.78V491.48h87.09Q693.82,491.48,718.13,471.18Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M1055.6,382.48l-23,18.7q-13.36-37.92-39.8-55t-69.73-17.1q-31,0-54,9.62t-35,26.18a62.09,62.09,0,0,0-12,37.4q0,20.31,10.16,33.67t34.46,22.7q24.3,9.36,67,16.83,68.94,11.76,97.78,34.2t28.86,62q0,28.32-16.57,50.23t-46.75,33.92q-30.2,12-69.19,12-55.57,0-93.77-22.18T782,583.39l23.51-18.17q10.68,37.93,43,58t79.88,20q45.95,0,75.07-18.7t29.12-51.3q0-28.31-23.77-44.88t-82.56-26.71q-49.68-8.55-78.54-20.84t-41.41-30.46q-12.55-18.15-12.56-45.42a86.94,86.94,0,0,1,15.77-50.22q15.75-23,44.88-36.6t68.12-13.63Q1021.94,304.47,1055.6,382.48Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M310.59,745.65H287.75l-6.41,15.6h-2l18.73-46h2.23l18.73,46h-2Zm-.77-1.81-9.26-22.77-1.39-3.76L297.78,721l-9.33,22.84Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M356.91,726.43v34.82H355.1L355,753a12.71,12.71,0,0,1-5.26,6.55,16.23,16.23,0,0,1-8.95,2.43q-5.7,0-9-3.2t-3.27-9.19V726.43h1.95v22.7c0,3.62.91,6.37,2.75,8.25s4.47,2.82,7.9,2.82a13.63,13.63,0,0,0,7.49-2,13.35,13.35,0,0,0,4.7-5,12.72,12.72,0,0,0,1.6-6V726.43Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M401.8,710.41v50.84H400l-.06-9.33a13.64,13.64,0,0,1-5,7.38,14.48,14.48,0,0,1-8.77,2.64q-7.45,0-11.7-4.8t-4.25-13.3q0-8.49,4.25-13.3t11.7-4.81a14.4,14.4,0,0,1,8.7,2.62,13.38,13.38,0,0,1,5,7.34V710.41ZM396.05,756q3.66-4.17,3.8-11.42v-1.53q-.15-7.23-3.8-11.45a12.14,12.14,0,0,0-9.64-4.21q-6.77,0-10.52,4.35t-3.76,12.08q0,7.73,3.76,12t10.52,4.31A12.18,12.18,0,0,0,396.05,756Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M416.39,719.05a2,2,0,0,1-.56-1.46,2,2,0,0,1,.59-1.47,2.12,2.12,0,0,1,1.5-.55,1.91,1.91,0,0,1,2,2,1.94,1.94,0,0,1-.56,1.46,2,2,0,0,1-1.46.56A2.07,2.07,0,0,1,416.39,719.05Zm2.51,42.2H417V726.43h1.95Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M460.18,730.54q4.35,4.8,4.36,13.3t-4.36,13.3q-4.35,4.8-11.94,4.8t-12.05-4.84q-4.38-4.83-4.38-13.26t4.38-13.27q4.4-4.83,12.05-4.84T460.18,730.54Zm-22.56,1.25q-3.86,4.4-3.86,12.05t3.86,12q3.87,4.38,10.62,4.38t10.52-4.38q3.83-4.39,3.83-12t-3.83-12.05q-3.84-4.38-10.52-4.38T437.62,731.79Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M520.73,761.25h-2.44l-18-46h2.09l15,38.37,2.09,5.64,2.08-5.64,15-38.37h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M550.11,719.05a2,2,0,0,1-.56-1.46,2,2,0,0,1,.59-1.47,2.12,2.12,0,0,1,1.5-.55,2,2,0,0,1,1.46.55,2,2,0,0,1,.56,1.47,1.92,1.92,0,0,1-2,2A2.07,2.07,0,0,1,550.11,719.05Zm2.51,42.2h-2V726.43h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M569.58,759.79a10.21,10.21,0,0,1-5-6.2l1.68-1a8.78,8.78,0,0,0,4.38,5.68,17.16,17.16,0,0,0,8.5,1.91c3.53,0,6.31-.69,8.35-2.09a6.48,6.48,0,0,0,3.07-5.64,5.48,5.48,0,0,0-2.51-4.73q-2.5-1.74-8.84-2.86-7.25-1.25-10.17-3.41a7.09,7.09,0,0,1-2.92-6.06,7.88,7.88,0,0,1,1.67-4.91A11.32,11.32,0,0,1,572.4,727a16.64,16.64,0,0,1,6.72-1.29q5.5,0,8.74,2.09a10.17,10.17,0,0,1,4.28,6.55l-1.67,1q-1.82-7.94-11.35-7.93a15.16,15.16,0,0,0-5.68,1,9.43,9.43,0,0,0-4,2.78,6,6,0,0,0-1.42,3.86,5.93,5.93,0,0,0,2.57,5.12q2.58,1.85,9.13,3,7,1.18,9.88,3.31a7,7,0,0,1,2.86,5.95,8.08,8.08,0,0,1-3.69,7q-3.69,2.58-10,2.57A18.57,18.57,0,0,1,569.58,759.79Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M633.3,726.43v34.82h-1.81l-.07-8.29a12.66,12.66,0,0,1-5.26,6.55,16.23,16.23,0,0,1-8.95,2.43q-5.7,0-9-3.2T605,749.55V726.43h1.95v22.7c0,3.62.91,6.37,2.75,8.25s4.47,2.82,7.9,2.82a13.63,13.63,0,0,0,7.49-2,13.35,13.35,0,0,0,4.7-5,12.72,12.72,0,0,0,1.6-6V726.43Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M679,759.72l-.14,1.6a5.33,5.33,0,0,1-2.78.62c-3.53,0-5.29-2.22-5.29-6.68v-.07a10.27,10.27,0,0,1-5.36,5,19.88,19.88,0,0,1-8.29,1.77q-5.16,0-8.11-2.22a7.32,7.32,0,0,1-3-6.2,7.9,7.9,0,0,1,3.1-6.55q3.09-2.43,9.85-3.69l11.77-2.16v-3.9a9.51,9.51,0,0,0-2.72-7.24q-2.71-2.57-7.8-2.57a13.62,13.62,0,0,0-7.55,1.91,11.42,11.42,0,0,0-4.43,6l-1.53-1.11q3.13-8.49,13.51-8.5,6,0,9.23,2.89t3.24,8.46v18.67a5.19,5.19,0,0,0,.94,3.37,3.25,3.25,0,0,0,2.68,1.15A4.88,4.88,0,0,0,679,759.72Zm-15.49-.6a12.87,12.87,0,0,0,5.19-3.27,7.45,7.45,0,0,0,2.09-5.33v-7.73l-10.87,2q-6.25,1.13-9,3.24a6.58,6.58,0,0,0-2.78,5.47,5.68,5.68,0,0,0,2.47,4.94,11.91,11.91,0,0,0,6.93,1.74A18.25,18.25,0,0,0,663.5,759.12Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M691.22,753.45c0,2.37.38,4.07,1.15,5.12a4.24,4.24,0,0,0,3.66,1.56,11.68,11.68,0,0,0,2.26-.17,10.4,10.4,0,0,0,2-.66l-.21,1.81a9.42,9.42,0,0,1-4.24.83,6,6,0,0,1-5-2q-1.61-2-1.6-6.34V710.41h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M769.49,725.11l-1.6,1.25a13,13,0,0,0-5.36-7.59,18.37,18.37,0,0,0-10-2.44,19.25,19.25,0,0,0-7.69,1.43,11.89,11.89,0,0,0-5.09,3.87,9.1,9.1,0,0,0-1.77,5.5,7.74,7.74,0,0,0,1.46,4.8,11.13,11.13,0,0,0,4.94,3.34,48.59,48.59,0,0,0,9.61,2.44,42.15,42.15,0,0,1,9.51,2.61,12.09,12.09,0,0,1,5.12,3.87,9.79,9.79,0,0,1,1.6,5.71,9.9,9.9,0,0,1-2.13,6.23,13.75,13.75,0,0,1-6,4.28,26.43,26.43,0,0,1-21.13-1.49,13.64,13.64,0,0,1-6.38-8.81l1.74-1.19a12.16,12.16,0,0,0,5.64,8.39,21,21,0,0,0,11.42,2.89,19,19,0,0,0,10.66-2.75,8.6,8.6,0,0,0,4.18-7.55,7.6,7.6,0,0,0-3.35-6.51q-3.33-2.42-11.42-3.73a48,48,0,0,1-10.41-2.72A12.11,12.11,0,0,1,737.7,733a9.86,9.86,0,0,1-1.63-5.81,10.75,10.75,0,0,1,2-6.27,13.9,13.9,0,0,1,5.75-4.6,20.67,20.67,0,0,1,8.77-1.74Q766.09,714.59,769.49,725.11Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M810.55,730.54q4.25,4.8,4.25,13.3t-4.25,13.3q-4.24,4.8-11.7,4.8a14.32,14.32,0,0,1-8.66-2.61,13.34,13.34,0,0,1-5-7.27v23.81h-2V726.43h1.81l.07,9.4a13.66,13.66,0,0,1,5-7.41,14.15,14.15,0,0,1,8.73-2.69Q806.31,725.73,810.55,730.54Zm-1.46,25.35q3.76-4.32,3.76-12t-3.76-12.08q-3.76-4.35-10.51-4.35a12,12,0,0,0-9.75,4.35q-3.63,4.35-3.62,11.8v.56q0,7.46,3.62,11.77a12,12,0,0,0,9.75,4.31Q805.33,760.2,809.09,755.89Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M856.78,744.88H828.16q.29,7.25,4,11.28t10.2,4a15,15,0,0,0,7.59-1.91A11.73,11.73,0,0,0,854.9,753l1.61.84a14,14,0,0,1-5.58,6,16.62,16.62,0,0,1-8.56,2.15q-7.59,0-11.87-4.8t-4.29-13.3q0-8.49,4.18-13.3T842,725.73q7,0,11,4.84t3.93,13.13ZM832,731.55q-3.66,4.14-3.8,11.52H855q-.21-7.38-3.58-11.52c-2.26-2.76-5.42-4.14-9.51-4.14S834.4,728.79,832,731.55Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M893.09,728.21a12.27,12.27,0,0,1,5,7l-1.74.91a11.11,11.11,0,0,0-4.28-6.44,13.16,13.16,0,0,0-7.84-2.26c-4.45,0-7.94,1.46-10.44,4.38S870,738.73,870,743.84s1.23,9.17,3.69,12,5.92,4.31,10.37,4.31a14.11,14.11,0,0,0,8.33-2.47,9.84,9.84,0,0,0,4.21-6.65l1.74.63a12.11,12.11,0,0,1-5,7.45,15.64,15.64,0,0,1-9.3,2.78q-7.59,0-11.8-4.8t-4.21-13.3q0-8.49,4.28-13.3t11.87-4.81A15.46,15.46,0,0,1,893.09,728.21Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M910,719.05a2,2,0,0,1-.55-1.46,1.91,1.91,0,0,1,.59-1.47,2.09,2.09,0,0,1,1.5-.55,2,2,0,0,1,1.46.55,2,2,0,0,1,.56,1.47,1.92,1.92,0,0,1-2,2A2.11,2.11,0,0,1,910,719.05Zm2.51,42.2h-2V726.43h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M958,759.72l-.14,1.6a5.33,5.33,0,0,1-2.78.62c-3.53,0-5.3-2.22-5.3-6.68v-.07a10.19,10.19,0,0,1-5.36,5,19.8,19.8,0,0,1-8.28,1.77c-3.44,0-6.14-.74-8.12-2.22a7.34,7.34,0,0,1-3-6.2,7.93,7.93,0,0,1,3.1-6.55q3.11-2.43,9.86-3.69l11.76-2.16v-3.9a9.5,9.5,0,0,0-2.71-7.24q-2.71-2.57-7.8-2.57a13.68,13.68,0,0,0-7.56,1.91,11.46,11.46,0,0,0-4.42,6l-1.53-1.11q3.13-8.49,13.51-8.5,6,0,9.23,2.89t3.23,8.46v18.67a5.25,5.25,0,0,0,.94,3.37,3.26,3.26,0,0,0,2.68,1.15A4.92,4.92,0,0,0,958,759.72Zm-15.49-.6a12.9,12.9,0,0,0,5.18-3.27,7.41,7.41,0,0,0,2.09-5.33v-7.73l-10.86,2q-6.27,1.13-9,3.24a6.59,6.59,0,0,0-2.79,5.47,5.69,5.69,0,0,0,2.48,4.94,11.86,11.86,0,0,0,6.92,1.74A18.31,18.31,0,0,0,942.5,759.12Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M970.22,753.45c0,2.37.38,4.07,1.15,5.12a4.22,4.22,0,0,0,3.65,1.56,11.73,11.73,0,0,0,2.27-.17,10.4,10.4,0,0,0,2-.66l-.21,1.81a9.44,9.44,0,0,1-4.25.83,5.94,5.94,0,0,1-4.94-2q-1.61-2-1.6-6.34V710.41h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M989.69,719.05a2,2,0,0,1-.55-1.46,1.91,1.91,0,0,1,.59-1.47,2.09,2.09,0,0,1,1.5-.55,2,2,0,0,1,1.46.55,2,2,0,0,1,.56,1.47,1.92,1.92,0,0,1-2,2A2.11,2.11,0,0,1,989.69,719.05Zm2.51,42.2h-2V726.43h2Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M1009.16,759.79a10.23,10.23,0,0,1-5-6.2l1.67-1a8.8,8.8,0,0,0,4.39,5.68,17.09,17.09,0,0,0,8.49,1.91c3.53,0,6.32-.69,8.36-2.09a6.49,6.49,0,0,0,3.06-5.64,5.47,5.47,0,0,0-2.5-4.73q-2.5-1.74-8.85-2.86-7.23-1.25-10.16-3.41a7.1,7.1,0,0,1-2.93-6.06,7.94,7.94,0,0,1,1.67-4.91A11.46,11.46,0,0,1,1012,727a16.71,16.71,0,0,1,6.72-1.29q5.5,0,8.74,2.09a10.19,10.19,0,0,1,4.29,6.55l-1.68,1q-1.8-7.94-11.35-7.93a15.11,15.11,0,0,0-5.67,1,9.37,9.37,0,0,0-4,2.78,6,6,0,0,0-1.43,3.86,5.94,5.94,0,0,0,2.58,5.12q2.58,1.85,9.12,3,7,1.18,9.89,3.31a7,7,0,0,1,2.85,5.95,8.08,8.08,0,0,1-3.69,7q-3.69,2.58-10,2.57A18.61,18.61,0,0,1,1009.16,759.79Z"
          transform="translate(-276.84 -304.47)"
        />
        <path
          d="M1063.07,759.23a9.93,9.93,0,0,1-3.13,1.95,10.6,10.6,0,0,1-4,.76c-2.78,0-4.91-.78-6.37-2.36s-2.19-4-2.19-7.11V728.1h-7v-1.67h7v-8.91l2-.56v9.47h13.3v1.67h-13.3v24.23q0,7.88,6.82,7.87a8.22,8.22,0,0,0,6.13-2.57Z"
          transform="translate(-276.84 -304.47)"
        />
      </motion.g>
    </svg>
  );
};

export default TextLogoSVG;
