import React from 'react';
import '../../App.css'
import {motion} from 'framer-motion'
import Andchuck from '../../../images/Website Images/Film Shoots/Andchuck Film Shoot.jpg'
import Lisa from '../../../images/Website Images/Film Shoots/Lisa Olivant Film Shoot.jpg'
import YoungM from '../../../images/Website Images/Film Shoots/Young Monarch Film Shoot.jpg'
// import WillUntold from '../../../images/Website Images/Past Events/Will Untold Orchestra.'
 


const videos = [
    {
      id: "5f0470888ad7b2b70c3a9079",
      title: "Space of a Cluttered Mind",
      artist: "Andchuck",
      imageURL: Andchuck,
      videoURL: "https://www.youtube.com/watch?v=-7sdXa7fYbU",
      description: "Lighting by JPS AV, Filming by 491 Film.Co",
      location: "RNCM Studio 1",
      
    },
    {
      id: "5f0470d08ad7b2b70c3a907a",
      title: "Find Me",
      artist: "Young Monarch",
      imageURL: YoungM,
      videoURL: "https://www.youtube.com/watch?v=ToXXfFXJwI4&feature=youtu.be",
      description: "Audio by JPS AV  Filming by 491 Film Co.",
      location: "Hope Mill Studio",
      
    },
    {
      id: "5f04a23824d891bacad3654e",
      title: "Unshaken",
      artist: "Van Horn Trading ",
      imageURL: Lisa,
      videoURL: "https://www.facebook.com/watch/?v=1121458218035894",
      description: "Audio by JPS AV  Filming by 491 Film Co.",
      location: "Blueprint Studios",
      
    },
    {
        id:"5f09bee98ae95a387dda83d1",
        title:"I Want You (She's so heavy) - Beatles Cover",
        artist:"Untold Orchestra",
        imageURL:"https://scontent.flhr4-1.fna.fbcdn.net/v/t1.0-9/71697799_1935649016580369_6117375821532364800_o.jpg?_nc_cat=101&_nc_sid=cdbe9c&_nc_ohc=jXjEyvQ3FTEAX8AQCLq&_nc_ht=scontent.flhr4-1.fna&oh=143485db1af5c9a4055ad481a50abb2f&oe=5F2F61D5",
        videoURL:"https://youtu.be/enNc2mdmoNo",
        description:"Live Audio by JPS, Mixing by Steve Poppleton",
        location:"NIAMOS",
    },
    {
        _id:"5f09d1568ae95a387dda83d2",
        title:"Motion",
        artist:"Mac Fray",
        imageURL:"https://scontent.flhr4-1.fna.fbcdn.net/v/t1.0-9/87474136_131479041718229_1978499585668022272_n.jpg?_nc_cat=105&_nc_sid=8bfeb9&_nc_ohc=EJSjwH0TPD0AX-O5HmI&_nc_ht=scontent.flhr4-1.fna&oh=4972a0b1d789dc5c853066b568c5b04d&oe=5F2E24A9",
        videoURL:"https://youtu.be/OX_dV8WHhrc",
        description:"Audio by JPS AV, Filming by 491 Film Co.",
        location:"The Yard",
    },
    {
        _id:"5f09d1d08ae95a387dda83d3",
        title:"Cerebral Folds",
        artist:"Flightless Birds",
        imageURL:"https://scontent.flhr4-2.fna.fbcdn.net/v/t1.0-9/85209691_2736772456404349_4509168524916162560_o.jpg?_nc_cat=110&_nc_sid=e3f864&_nc_ohc=8KtcwACamUYAX8MUH_k&_nc_ht=scontent.flhr4-2.fna&oh=161f3fe2f81922ea5554d275be5e6ef7&oe=5F2ECE59",
        videoURL:"www.youtube.com/watch?v=fH_IjiGvUtU",
        description:"Audio and Lighting by JPS AV, Video by 491 Film Co.",
        location:"Blueprint Studios"
    }
  ]

const childVariants = {
  hidden: {opacity:0},
  visible: {
      opacity: 1,
     
      transition: {
          when: "beforeChildren",
          delayChildren: 3
          },
      }    
}

const youtubeLinkClick = (url) => {
    window.open(url, "_blank")
}

const PlaceHolderVideos = () => {
    

        return (  
          <div className="event-container">
          <div className="eventText" >
              <h1>Recording/ Filming</h1>
              <h3>Full audio and video recording in conjunction with 491 Film.Co. Links to previous work below.</h3>
              <h3>Please get in touch for more information</h3>
          </div>
          <motion.div className="card-container"
          // variants={containerVariants}
          
          >
               
        {videos.map((currentVideo, i) => {  
            return(
            <motion.div className="video-card"
            whileTap={{scale: 0.95}}
            whileHover={{scale: 1.05}}
            animate="visible"
            initial="hidden"
            // custom={i}
            key={i}
            variants={childVariants}>
                <div className = "image-crop">
                    <img src={currentVideo.imageURL} alt="" />
                </div>  
                <div className="card-text">
                    <div>
                        <h1>{currentVideo.artist}</h1>
                        <h4>{currentVideo.title}</h4>
                        <h3>{currentVideo.description}</h3>
                </div>
                    <div className = "event-more-info">
                        <div>
                            <h6>{currentVideo.location}</h6>
                        </div>
                        <button onClick={() =>{youtubeLinkClick(currentVideo.videoURL)}} ><h4>Watch Video</h4></button>
                        {/* <button className="Button" onClick={openModal}><h4>More Info</h4></button> */}
                    </div>
                </div>
            </motion.div>   
         ) })
            }
            </motion.div>
        </div>
    )
}
    

 
export default PlaceHolderVideos