import React from "react";

import "../App.css";

import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const navBarVariants = {
  hidden: {
    opacity: 0,
    x: 1000,
    transition: { duration: 2 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 1000,
    transition: { duration: 2 },
  },
};
const backdropVariants = {
  hidden: {
    opacity: 0,

    transition: { duration: 2 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,

    transition: { duration: 1 },
  },
};

const SideBar = ({ openModal, showNav, toggleNav, toggleMenu }) => {
  const handleClick = () => {
    toggleNav();
    toggleMenu();
  };
  return (
    <AnimatePresence exitBeforeEnter>
      {showNav && (
        <motion.div
          className="sideBar-backdrop"
          onClick={handleClick}
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.div
            className="sideBar"
            variants={navBarVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {showNav && (
              <ul className="sideBar-links">
                <NavLink to="/home" activeClassName="selected">
                  <motion.li whileTap={{ scale: 0.95 }} onClick={handleClick}>
                    <h2>Home</h2>
                  </motion.li>
                </NavLink>
                <NavLink to="/services" activeClassName="selected">
                  <motion.li whileTap={{ scale: 0.95 }} onClick={handleClick}>
                    <h2>Services</h2>
                  </motion.li>
                </NavLink>
                <NavLink to="/about" activeClassName="selected">
                  <motion.li whileTap={{ scale: 0.95 }} onClick={handleClick}>
                    <h2>About</h2>
                  </motion.li>
                </NavLink>
                <motion.li whileTap={{ scale: 0.95 }} onClick={openModal}>
                  <h2>Contact</h2>
                </motion.li>
              </ul>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SideBar;
