import React from "react";
import Socials from "./Socials";
import { motion } from "framer-motion";
import "../App.css";

const Footer = ({ openModal }) => {
  return (
    <motion.div
      className="footer-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="footer-row">
        <ul>
          <h1 className="alt-color">Contact</h1>
          <li>Email: James@JPS-AV.co.uk</li>
          <li>Tel: James - 07873241250</li>
        </ul>
        <ul>
          <h1 className="alt-color">Visit</h1>
          <li>Sale</li>
          <li>Manchester M33</li>
        </ul>
        <ul>
          <h1 className="alt-color">Services</h1>
          <li>Event Hire</li>
          <li>Recording/ Video</li>
        </ul>
        <div className="footer-socials">
          <Socials className="footer-socials" openModal={openModal} />
        </div>
      </div>
      <div className="bottom">
        <h4>©2020 by JPS - Audio Visual Specialist</h4>
      </div>
    </motion.div>
  );
};
export default Footer;
