import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const Socials = ({ openModal }) => {
  return (
    <div className="socials">
      <motion.a
        href="https://www.instagram.com/jamespaulsound/"
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
      >
        <FontAwesomeIcon
          icon={["fab", "instagram-square"]}
          className="social-icon"
        />
      </motion.a>
      <motion.a
        href="https://www.facebook.com/JamesPaulSound"
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
      >
        <FontAwesomeIcon
          icon={["fab", "facebook-square"]}
          className="social-icon"
        />
      </motion.a>
      <motion.a whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }}>
        <FontAwesomeIcon
          icon={["fas", "envelope-square"]}
          className="social-icon"
          onClick={openModal}
          cursor="pointer"
        />
      </motion.a>
    </div>
  );
};

export default Socials;
