import React from "react";
import ReactPlayer from "react-player";
import "../App.css";

const VideoPlayer = () => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url="https://www.youtube.com/watch?v=PAOJTurcrf0&feature=youtu.be"
        width="100%"
        height="100%"
        loop={true}
        playing={true}
        controls={true}
        volume={0}
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
          facebook: {
            appId: "12345",
          },
        }}
      />
    </div>
  );
};

export default VideoPlayer;
