import React from "react";

import "../App.css";
import Logo from "../../images/Logos/JPS_Logo.png";
import Socials from "./Socials";
import { NavLink, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const NavBar = ({ openModal }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div className="navbar">
        <Link to="/home">
          <motion.img
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            src={Logo}
            alt="JPS Logo"
          />
        </Link>
        <ul className="nav-links">
          <NavLink to="/home" activeClassName="selected">
            <motion.li whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }}>
              <h2>Home</h2>
            </motion.li>
          </NavLink>
          <NavLink to="/services" activeClassName="selected">
            <motion.li whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }}>
              <h2>Services</h2>
            </motion.li>
          </NavLink>
          <NavLink to="/about" activeClassName="selected">
            <motion.li whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }}>
              <h2>About</h2>
            </motion.li>
          </NavLink>
          <motion.li
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            onClick={openModal}
          >
            <h2>Contact</h2>
          </motion.li>
        </ul>
        <Socials className="nav-socials" openModal={openModal} />
      </motion.div>
    </AnimatePresence>
  );
};

export default NavBar;
