import React from "react";
import "../App.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const buttonVariants = {
  menu: { opacity: 1 },
};

const NavButton = ({ toggleMenu, showMenu, toggleNav }) => {
  const handleClick = () => {
    toggleMenu();
    toggleNav();
  };

  return (
    <motion.div
      className="menu"
      variants={buttonVariants}
      // initial="menu"
      animate="menu"
    >
      {showMenu && (
        <FontAwesomeIcon
          icon={["fas", "bars"]}
          className="menu-icon"
          onClick={handleClick}
        />
      )}
      {!showMenu && (
        <FontAwesomeIcon
          icon={["fas", "times"]}
          className="menu-icon-close"
          onClick={handleClick}
        />
      )}
    </motion.div>
  );
};

export default NavButton;
