import React from "react";
import AboutPage from "../../Pages/About";
import HomePage from "../../Pages/Home";
import ServicesPage from "../../Pages/Services";
import { Switch, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
// import AdminPage from '../../Pages/AdminPage';

const PageRouter = ({ openModal, showModal, setShowModal }) => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        {/* <Route path="/admin" component={AdminPage} /> */}
        <Route path="/about">
          <AboutPage
            showModal={showModal}
            setShowModal={setShowModal}
            openModal={openModal}
          />
        </Route>
        <Route path="/services">
          <ServicesPage openModal={openModal} />
        </Route>
        <Route exact path="/">
          <HomePage openModal={openModal} />
        </Route>
        <Route path="/home">
          <HomePage openModal={openModal} />
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

export default PageRouter;
