import React, { useState, useEffect } from "react";
import "../../App.css";
import VideoCard from "./VideoCard";

const VideosList = () => {
  const [videos, setVideos] = useState([]);
  const loadVideos = async () => {
    try {
      const res = await fetch("/.netlify/functions/getVideos");
      const videos = await res.json();
      setVideos(videos);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadVideos();
  }, []);

  let videosList = videos;

  return (
    <div className="video-container">
      <div className="videoText">
        <h1>Recording/ Filming</h1>
        <h3>
          Full audio and video recording in conjunction with 491 Film.Co. Links
          to previous work below.
        </h3>
        <h3>Please get in touch for more information</h3>
      </div>
      <div className="card-container">
        {videosList.map((item, i) => (
          <VideoCard item={item} key={item._id} i={i} />
        ))}
      </div>
    </div>
  );
};

export default VideosList;
