import React  from 'react';
import WhatWeDo from '../App/Components/WhatWeDo'
import Testimonials from '../App/Components/Testimonials';
import '../App/App.css'

import Footer from '../App/Components/Footer';
import {motion} from 'framer-motion'

const containerVariants ={
    hidden:{
        opacity: 0,
        x: '-100vw'
    },
    visible:{
        opacity: 1,  
        transition: {duration: 0.5},
        x: 0
    },
    exit:{
        opacity: 0,
        x: '100vw',
        transition:{ease: 'easeInOut'}
    }
}
const AboutPage = ({openModal, showModal, setShowModal}) =>{
        return (
            <motion.div 
                variants={containerVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className="about-container"
                >
               
                    <WhatWeDo showModal={showModal} setShowModal={setShowModal} openModal={openModal}/>
                    <Testimonials />
                
                <Footer />
            </motion.div> 
        )
       
}

export default AboutPage;